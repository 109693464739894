.widget {
  margin-bottom: 30px;

  .widget-title {
    margin-bottom: 15px;
    padding-bottom: 10px;
    font-size: 16px;
    color: #333;
    font-weight: 500;
    border-bottom: 1px solid $border-color;
  }

  // latest Posts
  &.widget-latest-post {
    .media {
      margin-bottom: 10px;
      .media-object {
        width: 90px;
        height: auto;
        margin-right: 15px;
      }

      .media-heading {
        margin-bottom: 0;
        a {
          color: $black;
          font-size: 16px;
          transition: 0.3s;
          &:hover {
            color: $primary-color;
          }
        }
      }

      p {
        font-size: 12px;
        color: #808080;
      }
    }
  }

  //end latest posts

  // Caterogry
  &.widget-category {
    ul {
      li {
        a {
          color: #837f7e;
          @include transition (all, 0.3s, ease);
          border: 1px solid #eee;
          display: block;
          padding: 8px 15px;
          margin-bottom: -1px;

          &:before {
            padding-right: 10px;
          }

          &:hover {
            color: $primary-color;
            padding-left: 20px;
          }
        }
      }
    }
  }

  //end caterogry

  // Tag Cloud
  &.widget-tag {
    ul {
      li {
        margin-bottom: 10px;
        display: inline-block;
        margin-right: 5px;

        a {
          color: #837f7e;
          display: inline-block;
          padding: 8px 15px;
          border: 1px solid #dedede;
          border-radius: 30px;
          font-size: 14px;
          @include transition (all, 0.3s, ease);

          &:hover {
            color: $light;
            background: $primary-color;
            border: 1px solid $primary-color;
          }
        }
      }
    }
  }

}