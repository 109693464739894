#text_area {
    background-color: aqua ;
}

.single_dropDown {
    height: 2.8rem;
}

.form-dropdown {
    height: 38px; 
    padding: 0.375rem 0.75rem; 
    font-size: 1rem; 
    line-height: 1.5;
    border-radius: 4px; 
    border: 1px solid #ced4da; 
  }
  
  .fw-bold-comments{
    position: relative;
    right: 40%;
    font-weight: bold;
  }

 