.company-title{
  cursor: pointer;
 }

 .company-title:hover{ 
  color: #0d6efd;
  font-weight: 500; 
}

.dropdown-height {
  height: 40px; // Set this to match the search box height
}