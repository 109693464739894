
.edit-input {
  border-color: #ff8800 !important; 
}
.table-hover tr {
  height: 55px; 
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}


.d-flex {
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.firstName:hover {
  color: #0d6efd;
  font-weight: 500; 
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  background-color: rgb(71, 66, 76);
  margin-bottom: 0rem !important;
}
