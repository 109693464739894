.tile {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 24px;
    text-align: center; 
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9;
    width: 250px;
    height: 130px; 
    margin: 10px;
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
    box-sizing: border-box; 

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
      border: 1px solid black;
    }
  
    &--selected {
      transform: scale(1.05);
      border-color: #007bff;
      box-shadow: 0 15px 25px rgba(0, 123, 255, 0.3);
    }

    &__image {
      width: 100%;
      height: 150px; 
      object-fit: cover; 
      border-radius: 4px;
      background-color: #e0e0e0; 
    }
  
    &__title {
      font-size: 18px;
      margin: 12px 0;
      font-weight: bold;
      color: #333;
      flex-grow: 0;
    }
  
    &__description {
      font-size: 14px;
      color: #666;
      margin-bottom: 10px;
    }
}
.clients-tiles .tile {
  background-color: rgb(101, 94, 122); 
  color: #ffffff; 
  border: 1px solid rgba(255, 255, 255, 0.2); 
  padding: 19px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
  border-radius: 12px; 
  width: 251px;
  height: 121px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  &:hover {
    background-color: rgb(80, 75, 97);
    transform: translateY(-5px); 
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); 
    border: 1px solid rgba(255, 255, 255, 0.4); 
  }

  &__title {
    font-size: 18px;
    font-weight: 300; 
    color: rgba(255, 255, 255, 0.85); 
    letter-spacing: 0.5px; 
    margin-bottom: 12px; 
    line-height: 1.5; 
    text-transform: capitalize;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    text-align: center; 
    transition: color 0.3s ease;

    &:hover {
      color: #ffffff; 
      transition: color 0.4s ease;
    }
  }
}








