// Fonts 
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Volkhov:ital@1&display=swap');

body {
  line-height: 1.65;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

p {
  color: #7B7B7B;
  font-size: 15px;
  font-family: $primary-font;
  line-height: 1.7;
}