.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #007bff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
  }
  
  .scroll-to-top.show {
    opacity: 1;
    visibility: visible;
  }
  
  .scroll-to-top .icon {
    font-size: 24px;
  }
  