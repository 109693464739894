.table-bordered {
    text-align: center;
    th,
    td {
      vertical-align: middle;
    }
  
    input.form-control {
      text-align: left;
    }
  }
  
  .contact-form .btn {
    width: 120px;
  }
  