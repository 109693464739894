.footer {
  background: #F5F5F5;
  text-align: center;
  padding: 80px 0;

  p {
    font-size: 13px;
    line-height: 25px;
    color: #919191;
  }

  a {
    color: #595959;
  }

  .footer-manu {
    padding-bottom: 25px;

    ul {
      margin: 0px;
      padding: 0px;

      li {
        display: inline-block;
        padding: 10px 20px;

        a {
          display: inline-block;
          color: #494949;

          &:hover {
            color: #000;
          }
        }
      }
    }
  }

  .copyright {
    a {
      font-weight: 600;
    }
  }
}


// scroll-to-top
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  height: 40px;
  width: 40px;
  background: $primary-color;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  display: none;
  @include mobile {
    bottom: 15px;
    right: 15px;
  }
  &:hover {
    background-color: #333;
  }
}