.clients-logo-section{
	padding-top: 30px; 
	padding-bottom: 75px;
	.clients-logo-img{
		padding: 0px 50px; 
	}
}

.clients-logo {
	img {
		width: auto!important;
		padding:20px;
	}
}