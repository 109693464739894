.call-to-action{
	padding: 70px 0px;
	text-align: center;
	h2{
		color:$light;
	}
	p{
		color:$light;
	}
	.btn-main{
		padding: 15px 35px;
		font-size: 12px;
		margin-top: 30px;
	}
}