.single-product {
	padding: 60px 0 40px;

	.breadcrumb {
		background: transparent;

		li {
			color: $black;
			font-weight: 200;

			a {
				color: $black;
				font-weight: 200;
			}
		}
	}

	.product-pagination {
		li {
			display: inline-block;
			margin: 0 8px;

			&+li:before {
				padding: 0 8px 0 0;
				color: #ccc;
				content: "/\00a0";
			}

			a {
				color: $black;
				font-weight: 200;

				i {
					vertical-align: middle;
				}
			}
		}
	}
}



.single-product-slider {
	.carousel {
		.carousel-inner {
			.carousel-caption {
				text-shadow: none;
				text-align: left;
				top: 20%;
				bottom: auto;

				h1 {
					font-size: 50px;
					font-weight: 100;
					color: $black;
				}

				p {
					width: 50%;
					font-weight: 200;
				}

				.btn-main {
					margin-top: 20px;
				}
			}
		}

		.carousel-control {
			bottom: auto;
			background: $light;
			width: 6%;
			padding: 10px 0;

			i {
				font-size: 40px;
				text-shadow: none;
				color: #555;
			}
		}

		.carousel-indicators li {
			img {
				height: auto;
				width: 60px;
			}
		}

		.carousel-control.right,
		.carousel-control.left {
			background-image: none;
			top: 40%;
		}
	}
}



.single-product-slider .carousel-indicators {
	margin: 10px 0 0;
	overflow: auto;
	position: static;
	text-align: left;
	white-space: nowrap;
	width: 100%;
	overflow: hidden;

	li {
		background-color: transparent;
		-webkit-border-radius: 0;
		border-radius: 0;
		display: inline-block;
		height: auto;
		margin: 0 !important;
		width: auto;

		&.active img {
			opacity: 1;
		}

		&:hover img {
			opacity: 0.75;
		}

		img {
			display: block;
			opacity: 0.5;
		}
	}
}

.single-product-details {
	.color-swatches {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		align-items: center;

		span {
			width: 100px;
			color: #000;
			font-size: 13px;
			font-weight: 600;
		}

		a {
			display: inline-block;
			width: 36px;
			height: 36px;
			margin-right: 5px;
		}

		li {
			display: inline-block;
		}

		.swatch-violet {
			background-color: #8da1cd;
		}

		.swatch-black {
			background-color: #000;
		}

		.swatch-cream {
			background-color: #e6e2d6;
		}
	}

	.product-size {
		margin-top: 20px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		align-items: center;

		span {
			width: 100px;
			color: #000;
			font-size: 13px;
			font-weight: 600;
			display: inline-block;
		}

		.form-control {
			display: inline-block;
			width: 130px;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #000;
			font-size: 12px;
			border: 1px solid #e5e5e5;
			border-radius: 0px;
			box-shadow: none;
		}
	}

	.product-category {
		margin-top: 20px;

		>span {
			width: 100px;
			color: #000;
			font-size: 13px;
			font-weight: 600;
			display: inline-block;
		}

		ul {
			width: 140px;
			display: inline-block;

			li {
				display: inline-block;
				margin: 5px;
			}
		}
	}

	.product-quantity {
		margin-top: 20px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		align-items: center;

		>span {
			width: 100px;
			color: #000;
			font-size: 13px;
			font-weight: 600;
			display: inline-block;
		}

		.product-quantity-slider {
			width: 140px;
			display: inline-block;

			input {
				height: 34px;
			}

			.input-group-btn:first-child>.btn,
			.p-quantity .input-group-btn:first-child>.btn-group {
				margin-right: -2px;
			}

			button {
				border-radius: 0;
			}
		}
	}
}
















.bootstrap-touchspin .input-group-btn-vertical {
	position: relative;
	white-space: nowrap;
	width: 1%;
	vertical-align: middle;
	display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
	padding: 8px 10px;
	margin-left: -1px;
	position: relative;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
	border-radius: 0;
	border-top-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
	margin-top: -2px;
	border-radius: 0;
	border-bottom-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical i {
	position: absolute;
	top: 3px;
	left: 5px;
	font-size: 9px;
	font-weight: normal;
}