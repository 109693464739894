.toggle-expand {
    position: relative;
    font-size: 30px;
    line-height: 0.5;
    top: 6px;
    cursor: pointer;
    font-weight: bold;
    width: 30px;  /* Adds some space around the toggle button */
    text-align: center; /* Center align the + or - sign */
}

.expanded-content {
    padding: 10px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
    transition: max-height 0.3s ease;
}

.table-responsive {
    max-height: 90vh; /* Limits the table's height */
    overflow-y: auto; /* Adds a scrollbar within the table if needed */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.table-responsive::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.expanded-row {
    background-color: #f9f9f9; /* Lighter background for expanded content */
}

.expanded-content div {
    padding: 10px 0;
}

.expanded-content div strong {
    display: inline-block;
    width: 170px; /* Aligns the labels consistently */
}
.job-title{
   
    &:hover{
        cursor: pointer;
        color: #0d6efd ;

    }
}
