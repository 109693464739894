.custom-h1 {
    /* background-color: #f4f4f4; */
    padding: 15px;
    margin: 0;
    font-size: 1.5rem;
    text-align: center;
    border-bottom: 1px solid #ccc;
}
.btn{
    color: #fff;
}

.btn:hover{
    color:#dee2e6;
 }