.apply-job-form {
    // padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .form-section {
    padding: 20px 0;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .btn-default {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #0056b3;
    }
  }
  
  ul {
    padding: 0;
    list-style-type: none;
  
    li {
      margin-bottom: 10px;
    }
  }
  