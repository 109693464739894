.user-edit-container {
    margin: 20px;
  }
  
  
    table {
      width: 100%;
      border-collapse: collapse;
  
      th, td {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }
  
      th {
        background-color: #f9f9f9;
      }
  
      tr:hover {
        background-color: #f1f1f1;
      }
  
      tr:last-child td {
        border-bottom: none;
      }
    }
  
    .form-control {
      border: 1px solid #ccc;
      padding: 7px;
      transition: border-color 0.3s ease;
  }
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 150px;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}

.modal-title {
  font-weight: 700;
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
  margin-left: 29%;
}

.cancel-btn{
  background-color: #0d6efd;
}

.cancel-btn:hover{
   background-color: #1e75f7;
}

.modal-body {
  position: relative;
  flex: 1 1;
  padding: var(--bs-modal-padding);
  left: 16%;
  font-weight: 490;
}

.icon-large {
  font-size: 24px; 
  margin-right: 5px;
}

.btn-blue {
  background-color: #0d6efd;
  color: white;
}

.btn-blue:hover{
  background-color: #1d72f2;

}

.form-control {
  height: 40px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.form-control:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.25);
}

.mb-3 {
  margin-bottom: 1rem;
}
