.description-area {
  height: 200px !important;
}

.contact-form {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
}

.select__input-container {
  input[type=text] {
    height: 0;
  }
}