.btn-default {
    width: 100%;
    height: 60px;
    background: #47424C;
    border: none;
    color: #fff;
    font-size: 18px;
}
.btn-default:hover{
    background: #584f61;
    color: #fff;

}

.error-space {
    height: 20px;
   
}
.error-message {
    color: red;
    font-size: 12px;
    opacity: 1; /* Initially hidden but space is reserved */
    transition: opacity 0.3s ease; 
}

.form-control {
    border: 1px solid #ccc;
    padding: 7px;
    transition: border-color 0.3s ease;
  
    &.input-has-value {
      border-color: #007bff ; // color when input has a value
    }

    &:focus {
      border-color: #007bff !important; // color on focus
      outline: none; // optional: to remove default outline on focus
    }
}


