#wrapper-work {
	overflow: hidden;
	padding-top: 100px;

	ul {
		li {
			width: 50%;
			float: left;
			position: relative;

			img {
				width: 100%;
				height: 100%;
			}

			.items-text {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				color: #fff;
				background: rgba(0, 0, 0, 0.60);
				padding-left: 44px;
				padding-top: 140px;

				h2 {
					padding-bottom: 28px;
					padding-top: 75px;
					position: relative;

					&:before {
						content: "";
						position: absolute;
						left: 0;
						bottom: 0;
						width: 75px;
						height: 3px;
						background: #fff;
					}
				}

				p {
					padding-top: 30px;
					font-size: 16px;
					line-height: 27px;
					font-weight: 300;
					padding-right: 80px;
				}
			}
		}
	}
}


/*--
	features-work Start 
--*/

#features-work {
	padding-top: 50px;
	padding-bottom: 75px;

	.block {
		ul {
			li {
				width: 19%;
				text-align: center;
				display: inline-block;
				padding: 40px 0px;
			}
		}
	}
}